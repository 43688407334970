import React from 'react'
import primitives from '@veneer/primitives'
import { useMediaQuery } from '@jarvis/react-portal-addons'
import { Button, Modal, IconWarningAlt, Group } from '@veneer/core'
import useRootContext from '../../../contexts/Root/useRootContext'
import {
  ModalTitle,
  ModalBox,
  ModalContent,
  Gap12px,
  FooterContainer
} from './styles'

const ConfirmationModal = ({
  showModal,
  OnConfirm,
  OnClose,
  modalTitle,
  modalContent,
  alignFooter
}) => {
  const { localization } = useRootContext()
  const { t } = localization.useReactTranslatorHook()

  const isMobile = useMediaQuery(
    `(max-width: ${primitives.layout.breakpoint2}px)`
  )
  const orientation = isMobile ? 'vertical' : 'horizontal'

  return (
    <Modal
      show={showModal}
      id="confirmation-modal"
      title=""
      alignFooter={alignFooter}
      footer={
        <FooterContainer className="vn-button-group--responsive modal-btn">
          <Group orientation={orientation}>
            <Button
              appearance="secondary"
              onClick={OnClose}
              data-testid="confirmation-modal-cancel-btn"
            >
              {t('application.myAccount.unsavedChanges.cancel')}
            </Button>
            <Gap12px />
            <Button
              appearance="primary"
              onClick={OnConfirm}
              data-testid="leave-button"
            >
              {t('application.myAccount.unsavedChanges.leave')}
            </Button>
          </Group>
        </FooterContainer>
      }
    >
      <ModalBox>
        <div>
          <ModalContent>
            <IconWarningAlt
              className="Warning-alt"
              filled
              color="darkOrange6"
              size={40}
            />
            <ModalTitle
              className="title-small"
              data-testid="confirmation-modal-title"
            >
              {modalTitle}
            </ModalTitle>{' '}
          </ModalContent>
        </div>
      </ModalBox>
      <div
        className="subtitle-regular"
        data-testid="confirmation-modal-description"
      >
        {modalContent}
      </div>
    </Modal>
  )
}

export default ConfirmationModal
