import { PhoneNumberUtil } from 'google-libphonenumber'

export function validatePhoneNumber(phoneNumber?: string) {
  const instance = PhoneNumberUtil.getInstance()
  try {
    const phoneNumberUnformatted = getNumberUnformatted(phoneNumber)
    const number = instance.parseAndKeepRawInput(phoneNumberUnformatted)
    return instance.isValidNumber(number)
  } catch (exception) {
    return false
  }
}

export function getNumberUnformatted(phoneNumber?: string) {
  let phoneNumberUnformatted = ''
  if (phoneNumber) {
    phoneNumberUnformatted = phoneNumber.replace(/[\D\s]+/g, '')
    if (phoneNumberUnformatted.length > 0) {
      phoneNumberUnformatted = '+' + phoneNumberUnformatted
    }
  }
  return phoneNumberUnformatted
}

export function formatNumberAsYouType(phoneNumber?: string) {
  let formattedNumber = ''
  const phoneNumberUnformatted = getNumberUnformatted(phoneNumber)
  if (phoneNumber) {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance()
      const number = phoneUtil.parseAndKeepRawInput(phoneNumberUnformatted)
      const regionCode = phoneUtil.getRegionCodeForNumber(number)
      formattedNumber = phoneUtil.formatInOriginalFormat(number, regionCode)
    } catch (e) {
      formattedNumber = phoneNumberUnformatted
    }
  }

  return formattedNumber
}
