import React, { useMemo } from 'react'
import RootContext, { RootContext as RootContextType } from './RootContext'
import { getMockedLocalization, shouldMockLocalization } from './mockedShell'
import resources from '../../assets/locale'

const RootProvider = ({ children, ...props }: RootContextType) => {
  const { localization: shellLocalization, enableLocalization } = props

  const localization = shouldMockLocalization(
    !enableLocalization,
    shellLocalization
  )
    ? getMockedLocalization(resources)
    : shellLocalization

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  )

  const providerValue = {
    ...props,
    localization
  }

  return (
    <RootContext.Provider value={providerValue}>
      <TranslatorProvider resources={resources}>{children}</TranslatorProvider>
    </RootContext.Provider>
  )
}

export default RootProvider
