import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Select, TextBox } from '@veneer/core'

export const FormInput = styled(TextBox)``

export const MarginBottomSize4 = styled.div`
  width: auto;
  margin-bottom: ${primitives.layout.size4}px;
`

export const FormSelect = styled(Select)``

export const FormSelectWithSeparateLabel = styled(Select)``

export const Form = styled.form`
  margin-top: 20px;
`

export const FormFields = styled.div`
  margin: ${primitives.layout.size4}px 0;
  max-width: ${(props) =>
    props.formMaxWidth ? `${props.formMaxWidth}` : '272px'};
`

export const Container = styled.div`
  flex-grow: 1;
  .padding-top-0 {
    padding-top: 0px !important;
  }
  h4 {
    color: ${primitives.color.gray10};
    font-size: ${primitives.typography.size7};
    line-height: ${primitives.typography.lineHeight6};
  }
  > div {
    color: #000;
  }
  #profile-container div > div {
    margin-bottom: 0px !important;
  }
  #profile-form {
    margin-top: 26px;
    .btnContainer {
      button:nth-of-type(1) {
        width: 145px;
      }
      button:nth-of-type(2) {
        width: 80px;
      }
    }
  }
`

export const FormLink = styled.div`
  margin: ${primitives.layout.size4}px 0px 0px ${primitives.layout.size2}px;
  a {
    color: ${primitives.color.hpBlue7};
    font-size: ${primitives.typography.size2};
    line-height: ${primitives.typography.lineHeight2};
  }

  a:hover {
    color: ${primitives.color.hpBlue8};
  }
`

export const ProgressBar = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const GridSeparator = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const TitleContent = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;

    &:first-child:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const MainContent = styled.div`
  max-width: 100%;
  div#profile,
  div#organization {
    padding-top: 14px !important;
    padding-bottom: ${primitives.layout.size3}px !important;
  }
  > * {
    &:not(:last-child) {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`

export const TemplateContainer = styled.div`
  width: 100vw;
  min-height: fit-content;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px 24px 32px;

  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
export const TextBoxContainer = styled.div`
  max-width: 352px;
`
