import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const ModalBox = styled.div`
  padding-bottom: ${primitives.layout.size5}px;
`

export const ModalTitle = styled.label`
  line-height: ${primitives.typography.lineHeight6};
  vertical-align: middle !important;
  margin: 2px 0 0 ${primitives.layout.size1}px;
`
export const ModalContent = styled.div`
  magin-bottom: ${primitives.layout.size1}px;
  display: flex;
`

export const Gap12px = styled.div`
  width: 12px;
  height: auto;
`
export const FooterContainer = styled.div`
  && {
    button:first-child {
      margin-left: 0;
    }
  }
`
