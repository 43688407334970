import React from 'react'
import { pathOr } from 'ramda'
import PropTypes from 'prop-types'

const TranslatorProvider = ({ children }) => <div>{children}</div>

TranslatorProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const shouldMockLocalization = (mock, shellLocalization) => {
  if (mock) {
    return true
  }

  if (!shellLocalization || !shellLocalization.enabled) {
    return true
  }

  return false
}

export const getMockedLocalization = (resources) => ({
  useReactTranslatorHook: () => ({
    t: (id = '', options = {}) => {
      let str = pathOr(id, id.split('.'), resources.en_US)

      Object.entries(options).forEach(([key, value]) => {
        str = str.replace(`{{${key}}}`, value)
      })

      return str
    }
  }),
  getReactTranslatorProvider: () => TranslatorProvider
})
