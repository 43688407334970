import { createContext } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import ShellV1Type, { Localization } from '../../types/shell/v1'
import ShellV2Type from '../../types/shell/v2'

type Resource = { [key: string]: Resource | string }

export type RootContext = {
  children?: React.ReactNode
  country: string
  enableLocalization: boolean
  localization: Localization
  resources: Resource
  shell: ShellV1Type & ShellV2Type
  stack: Stack
}

const RootContext = createContext({} as RootContext)

export default RootContext
