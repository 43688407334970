import { AssetsProvider } from '@jarvis/web-assets-provider'
import { Stack } from '@jarvis/web-stratus-client'
import { profileFormProps } from '..'
import AssetsProviderFactory from '../../../assets/AssetsProviderFactory'
import { User } from '../interface/profileInterface'

export const CHANGE_PASSWORD_URLS = {
  default: 'https://myaccount.stg.cd.id.hp.com/uaa/change-password',
  prod: 'https://myaccount.id.hp.com/uaa/change-password'
}

let localProps: profileFormProps

export const setProps = (props: profileFormProps) => {
  localProps = props
}

export const getProps = () => {
  return localProps
}

export const getText = (subkey: string): string => {
  const props = getProps()
  const assetsProvider: AssetsProvider = AssetsProviderFactory.create(
    props.language,
    props.country
  )
  const assetKey = `application.${subkey}`
  return assetsProvider.getText(assetKey)
}

export const fetchCountriesInfo = async (userProfile: User) => {
  return {
    supportedLocaleSet: [userProfile.language],
    supportedCountrySet: [userProfile.country]
  }
}
const extractRegionInfo = (
  arrayInfo: string[],
  localizationPrefix: string,
  t: (id: string) => string
) => {
  return arrayInfo.map((value, index) => ({
    value: index,
    label: t(`application.myAccount.profile.${localizationPrefix}.${value}`),
    stringValue: value,
    languageCodeList: value
  }))
}

export const extractCountriesInfo = (
  countries: string[],
  t: (id: string) => string
) => extractRegionInfo(countries, 'countries', t)

export const extractLanguagesInfo = (
  languages: string[],
  t: (id: string) => string
) => extractRegionInfo(languages, 'languages', t)

export const getUrlChangePassword = () => {
  const props = getProps()
  switch (props.stack) {
    case Stack.prod:
      return CHANGE_PASSWORD_URLS.prod
    default:
      return CHANGE_PASSWORD_URLS.default
  }
}
