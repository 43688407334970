import { path } from 'ramda'

const activity = 'Settings-v01'
const screenPath = '/ReactAccountProfile/'
const eventDetailVersion = '1.3.0'

export const publishEvent = (event, options?) => {
  const publishCmdEvents = path(
    ['Shell', 'v1', 'analytics', 'publishCdmEvents'],
    window
  )

  if (!publishCmdEvents) {
    return
  }

  publishCmdEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType:
        'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      options,
      version: '1.4.0'
    }
  ])
}

const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked'
}

const baseEvent = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenMode: 'Organization',
  screenName: 'AccountProfile'
}

// Events
export const settingsAccountProfileOrgNameTextButtonClicked = {
  ...baseEvent,
  controlName: 'OrgNameText',
  controlDetail: 'Edited',
  version: eventDetailVersion
}

export const settingsAccountProfileOrgDescTextButtonClicked = {
  ...baseEvent,
  controlName: 'OrgDescText',
  controlDetail: 'Edited',
  version: eventDetailVersion
}

export const settingsAccountProfileApplyButtonClicked = {
  ...baseEvent,
  controlName: 'ApplyButton',
  version: eventDetailVersion
}

export const settingsAccountProfileCancelButtonClicked = {
  ...baseEvent,
  controlName: 'CancelButton',
  version: eventDetailVersion
}
