import styled, { css, keyframes } from 'styled-components'
import primitives from '@veneer/primitives'

export const slideUp = keyframes`
  from {
    bottom: -72px;
  }
  to {
    bottom: 0;
  }
`

export const slideDown = keyframes`
  from {
    bottom: 0;
  }
  to {
    bottom: -72px;
  }
`

export const Container = styled.div`
  background: ${primitives.color.hpBlue0};
  bottom: ${(props) => (props.show ? '0' : '-72px')};
  box-sizing: border-box;
  column-gap: ${(props) => props.gapSize};
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-end;
  left: 0;
  padding: ${primitives.layout.size3}px ${primitives.layout.size6}px
    ${primitives.layout.size3}px 0;
  position: fixed;
  width: 100%;
  z-index: 1;

  ${(props) =>
    !props.fixed
      ? css`
          animation: ${props.show ? slideUp : slideDown} 0.3s ease-out;
        `
      : ''}
`
