import profileInfoMock from '../../../../demo/mock/user.json'
import { path, pathOr } from 'ramda'
import { getProps } from './commonMethods'
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'
import { AccountMgtSvcClientExt } from '../../../ext/account-mgt-svc'
import { UserTenantMgtSvcClientExt } from '../../../ext/user-tenant-mgt-svc'
import {
  AccountInfo,
  ActiveUser,
  DefaultFormInterface,
  ProfileInfo
} from '../interface/profileInterface'
import { FormTabs } from '..'
import {
  getNumberUnformatted,
  formatNumberAsYouType
} from '../../../utils/phoneNumberFormatter'

const getActiveUser = async (): Promise<ActiveUser> => {
  const props = getProps()
  const client = new UserTenantMgtSvcClientExt(
    props.stack,
    props.authProvider.createOrgedAuthProvider()
  )
  const response = await client.getCurrentActiveUser()
  return {
    resourceId: path(['data', 'resourceId'], response),
    email: path(['data', 'email', 'value'], response),
    phoneNumber: path(['data', 'phoneNumber', 'value'], response),
    familyName: path(['data', 'familyName'], response),
    givenName: path(['data', 'givenName'], response),
    locale: path(['data', 'locale'], response)
  }
}

const getAccountInfo = async (): Promise<AccountInfo> => {
  const props = getProps()
  const client = new AccountMgtSvcClient(
    props.stack,
    props.authProvider.createOrgedAuthProvider()
  )
  const response = await client.getAccount()
  return response.data
}

export const getProfileInfo = async (): Promise<ProfileInfo> => {
  const props = getProps()
  if (props.mockStratus) {
    return profileInfoMock
  }
  const results = await Promise.all([getActiveUser(), getAccountInfo()])
  const [user, account] = results
  const data = {
    ...user,
    accountId: path(['resourceId'], account),
    accountName: path(['accountName'], account),
    description: pathOr('', ['description'], account),
    country: path(['regionId'], account),
    userId: path(['resourceId'], user),
    language: path(['locale'], user) || 'en_US',
    region: pathOr(path(['regionId'], account), ['countrySet', 0], account),
    phoneNumber: formatNumberAsYouType(path(['phoneNumber'], user))
  }
  return data
}

export const updateProfile = async (
  formState: DefaultFormInterface,
  tabId?: string
): Promise<ActiveUser> => {
  const props = getProps()
  const userInput = getUserInput(formState)
  const accountInput = getAccountInput(formState)
  let updateProfileMock = profileInfoMock

  if (props.mockStratus) {
    updateProfileMock = {
      ...updateProfileMock,
      ...userInput,
      ...accountInput
    }
    return updateProfileMock
  }

  if (props.showOrganizationForm && tabId === FormTabs.Ids.Organization) {
    const client = new AccountMgtSvcClientExt(
      props.stack,
      props.authProvider.createOrgedAuthProvider()
    )
    const response = await client.updateAccountInfo(
      formState.accountId.value,
      accountInput
    )
    return response.data
  } else {
    const client = new UserTenantMgtSvcClientExt(
      props.stack,
      props.authProvider.createOrgedAuthProvider()
    )
    const response = await client.updateUserInfo(
      formState.userId.value,
      userInput
    )
    return response.data
  }
}

const getUserInput = (formState: DefaultFormInterface) => {
  let phoneNum
  formState.phoneNumber.value != ''
    ? (phoneNum = getNumberUnformatted(formState.phoneNumber.value))
    : (phoneNum = ' ')
  const input = {
    givenName: formState.givenName.value,
    familyName: formState.familyName.value,
    email: formState.email.value,
    phoneNumber: phoneNum,
    locale: formState.language.value
  }
  return input
}

const getAccountInput = (formState: DefaultFormInterface) => {
  const input = {
    accountName: formState.accountName.value,
    description: formState.description.value || null
  }
  return input
}
