import { AxiosResponse } from 'axios'
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'

export class AccountMgtSvcClientExt extends AccountMgtSvcClient {
  /**
   * Update a account of region on behalf of the authenticated user.
   *
   * @param {string} accountId account's resourceid
   * @param {Record<string, unknown>} accountInfo Account object
   * @returns Axios response object
   */
  async updateAccountInfo(
    accountId: string,
    account: Record<string, unknown>
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.patch({
      url: `${this.accountsEndpoint}/${accountId}`,
      data: { ...account }
    })
  }
}
